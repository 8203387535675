/* eslint-disable no-nested-ternary */
import React from 'react'
import { Layout } from '../../components/layout'
import Meta from '../../components/layout/meta'
import { RelativeContainer, ComponentContainer, ColoredBackgroundWithProps } from '../../components/shared/landingPages/layoutComponents'
import Header from '../../components/shared/landingPages/header'
import TopHighlights from '../../components/shared/landingPages/topHighlights'
import HighlightedFunctionality from '../../components/shared/landingPages/highlightedFunctionality'
import FeatureLinks from '../../components/shared/landingPages/featureLinks'
import Benefits from '../../components/shared/landingPages/benefits'
import Book from '../../images/boekhoudinglp/book.js'
import Personal from '../../images/boekhoudinglp/personal.js'
import Deposit from '../../images/boekhoudinglp/desposit.js'
import Bonnen from '../../images/boekhoudinglp/bonnen.js'
import Credit from '../../images/icons/credit.js'
import headerPng from '../../images/boekhoudinglp/headerImage.png'
import upToDate from '../../images/boekhoudinglp/upToDate.png'
import benefit from '../../images/boekhoudinglp/benefits.png'
import { Title } from '../../components/shared/landingPages/title'
import { Accordeon } from '../../components/UI'

const headerData = {
    title: `Alles wat je nodig hebt voor je boekhouding`,
    text: `Scan bonnen, verstuur facturen en regel eenvoudig je zaken bij de Belastingdienst: Tellow maakt boekhouden simpel en overzichtelijk.`,
    headerImage: headerPng,
    textCTA: `Gebruik Tellow gratis`,
}

// tets
const topHighlightData = {
    title: `Je boekhouding regelen met Tellow`,
    points: [
        {
            Icon: Book,
            title: `Handig adresboek`,
            body: `
            Sla je contacten op en vind gemakkelijk de gegevens van bedrijven via de integratie met de Kamer van Koophandel.
        `,
        },
        {
            Icon: Personal,
            title: `Persoonlijk advies`,
            body: `
            Krijg altijd deskundige ondersteuning en advies. Onze adviseurs en boekhouders kunnen al jouw vragen voor je beantwoorden.
        `,
        },
        {
            Icon: Deposit,
            title: `Snel je geld ontvangen`,
            body: `
            Krijg inzicht in welke facturen openstaan en stuur automatische betalingsherinneringen zodat je sneller betaald wordt.
        `,
        },
        {
            Icon: Bonnen,
            title: `Bonnen scannen`,
            body: `
            Scan je bonnetjes eenvoudig met je mobiel. Alle bonnen en worden digitaal bewaard en zijn overzichtelijk terug te vinden in je dashboard.
        `,
        },
    ],
}

const highlightedFunctionalityData = {
    title: `Boekhouding up-to-date`,
    points: [
        {
            title: 'Transacties inladen',
            description: 'Integreer Tellow met jouw bankrekening en je transacties zijn altijd up-to-date.',
        },
        {
            title: 'Uitgaven inboeken',
            description: 'Je uitgaven worden automatisch aan de juiste bonnetjes of facturen gekoppeld.',
        },
    ],
    firstStepImage: upToDate,
    textCTA: `Probeer het zelf`,
}

const benefitsData = {
    points: [
        {
            title: 'Btw-aangifte',
            body: 'Tellow houdt automatisch je belastingzaken bij. Zodra je btw-aangifte wilt doen staat deze ingevuld voor je klaar. Met een druk op de knop is het geregeld met de Belastingdienst.',
            links: 'Doe jouw aangifte met Tellow',
            url: `https://app.tellow.nl/registreer#/`,
        },
        {
            title: 'Compleet',
            body: 'Automatisch opgestelde en bijgewerkte rapportages zorgen ervoor dat je een volledig overzicht hebt van je zakelijke financiën.',
            links: 'Probeer nu',
            url: `https://app.tellow.nl/registreer#/`,
        },
        {
            title: 'Real-time overzicht',
            body: 'Automatisch opgestelde en bijgewerkte rapportages zorgen ervoor dat je een volledig overzicht hebt van je zakelijke financiën.Met de app van Tellow heb je real-time inzicht in de boekhouding van je bedrijf zodat je altijd en overal je administratie kunt doen.',
            links: 'Maak een account aan',
            url: `https://app.tellow.nl/registreer#/`,
        },
    ],
    benefitImage: benefit,
    altImage: `Benefit Image`,
}

const featureData = [
    {
        Icon: Credit,
        title: `Btw-aangifte doen`,
        text: `Door de koppeling met de belastingdienst doe jij nu je aangifte in enkele klikken. Super makkelijk en snel.`,
        link: `Lees meer over aangiftes doen met Tellow`,
        path: `btw-aangifte`,
    },
    {
        Icon: Credit,
        title: `Bonnen scannen`,
        text: `Scan gemakkelijk al je bonnetjes via de app. Nooit meer bonnetjes bewaren`,
        link: `Lees meer over het scannen van bonnen`,
        path: `bonnen`,
    },
]

const FAQ = [
    {
        question: 'Wat is boekhouden voor zzp’ers?',
        answer: `
            Boekhouden is het verwerken van al je financiële administratie. Boekhouden voor zzp’ers houd in:

            <ol>
                <li>Groot boek administratie opstellen.</li>
                <li>Facturen opstellen, versturen en verwerken.</li>
                <li>Inkomende facturen betalen en verwerken.</li>
                <li>Btw-aangiftes indienen.</li>
                <li>Aangifte voor de inkomstenbelasting doen.</li>
                <li>Als je werkt met de inkoop en verkoop van goederen ben je verplicht minstens 1 keer per jaar de goederen te inventariseren en de voorraad en waarde te vermelden in je boekhouding.</li>
            </ol>
        `,
    },
    {
        question: 'Heb je als zzp’er een boekhouder nodig?',
        answer: `
            Het is voor zzp’ers niet verplicht om een boekhouder te hebben, maar het wordt vaak wel aangeraden. Boekhouding is een tijdrovend klusje en ondernemers besteden vaak liever tijd aan hun onderneming i.p.v. aan hun boekhouding. Als je hier meer over wilt weten kun je onze <a href="https://www.tellow.nl/blog/heb-ik-een-boekhouder-nodig-als-zzper/" target="_blank">blog</a> over dit onderwerp lezen.
        `,
    },
    {
        question: 'Wat zijn de taken van een boekhouder?',
        answer: `
            Boekhouders nemen gedeeltelijk of volledig je boekhouding over. Je blijft wel altijd zelf verantwoordelijk voor een kloppende boekhouding.
            <br/><br/>
            Voor de volgende taken kun je een boekhouder inhuren:

            <ul>
                <li>Het bijhouden van je financiële transacties.</li>
                <li>Factureren.</li>
                <li>Het opstellen van belastingaangiften.</li>
                <li>Het opstellen van jaarrekeningen, winst- en verliesrekeningen en balansen.</li>
                <li>Het stellen van financiële vragen.</li>
            </ul>
        `,
    },
    {
        question: 'Hoe ziet een grootboek eruit?',
        answer: `
            Een grootboek vormt de basis van je boekhouding en bestaat uit een aantal grootboekrekeningen. Grootboekrekeningen worden opgesplitst in balansrekeningen en resultaatrekeningen.
        `,
    },
    {
        question: 'Wat moet je bijhouden als zzp’er?',
        answer: `
            Als zzp'er moet je diverse zaken bijhouden. Dit kan tegenwoordig ook online bijgehouden worden. De zaken die je moet bijhouden zijn:

            <ol>
                <li>Grootboek</li>
                <li>Bonnetjes</li>
                <li>Debiteuren- en crediteurenadministratie</li>
                <li>In- en verkoopadministratie</li>
                <li>Bankafschriften</li>
                <li>Urenregistratie</li>
                <li>Reiskosten</li>
                <li>Belastingaanslagen</li>
                <li>Rittenadministratie</li>
            </ol>
        `,
    },
    {
        question: 'Hoe krijg ik mijn administratie op orde?',
        answer: `
            Met het stappenplan hieronder kun je jouw administratie weer helemaal op orde brengen.

            <ul>
                <li>Wees gedisciplineerd. Ruim je rekeningen gelijk op en laat het niet rondslingeren.</li>
                <li>Berg alles op in een map. Maak de mappen overzichtelijk door ze in verschillende rubrieken op te delen.</li>
                <li>Plaats reminders.</li>
                <li>Check je rekeningen. Zie of alles goed verwerkt is.</li>
                <li>Leg jaaropgaven apart.</li>
                <li>Berg belangrijke papieren overzichtelijk op.</li>
                <li>Maak duidelijke afspraken. (Deze optie geldt als je gezamenlijk de administratie doet)</li>
                <li>Loop je financiën regelmatig door.</li>
                <li>Creëer een buffer.</li>
            </ul>
        `,
    },
]

const Boekhouding = () => (
    <Layout>
        <Meta
            keywords={['zzp', 'boekhouden', 'tellow']}
            path="/functies/boekhouding"
            title="Boekhouding | Tellow.nl"
            description="Snel online boekhouden met hét online boekhoudprogramma voor zzp'ers ✅ 100.000+ ondernemers gingen je voor ✅ Registreer gratis!"
        />
        <Header data={headerData} purpleBackground />

        <RelativeContainer>
            <RelativeContainer>
                <ComponentContainer>
                    <HighlightedFunctionality data={highlightedFunctionalityData} />
                </ComponentContainer>
            </RelativeContainer>
        </RelativeContainer>

        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <TopHighlights data={topHighlightData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <RelativeContainer>
            <ComponentContainer>
                <Benefits data={benefitsData} />
            </ComponentContainer>
        </RelativeContainer>

        <RelativeContainer>
            <ComponentContainer>
                <FeatureLinks data={featureData} />
            </ComponentContainer>
        </RelativeContainer>

        <RelativeContainer>
            <ComponentContainer direction="column" padding={1.5}>
                <Title>Veelgestelde vragen</Title>
                {FAQ.map(({ question, answer }) => (
                    <Accordeon key={question} question={question}>
                        <div dangerouslySetInnerHTML={{ __html: answer }} />
                    </Accordeon>
                ))}
            </ComponentContainer>
        </RelativeContainer>
    </Layout>
)

export default Boekhouding
