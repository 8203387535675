import React from 'react'
import tw from 'twin.macro'

const Deposit = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.6726 13.1275C15.3331 12.7996 14.8784 12.6182 14.4065 12.6223C13.9345 12.6264 13.4831 12.8157 13.1493 13.1494C12.8156 13.4832 12.6263 13.9346 12.6222 14.4066C12.6181 14.8785 12.7995 15.3332 13.1274 15.6727L16.7274 19.2727C17.065 19.6101 17.5227 19.7997 18 19.7997C18.4773 19.7997 18.9351 19.6101 19.2726 19.2727L22.8726 15.6727C23.2005 15.3332 23.3819 14.8785 23.3778 14.4066C23.3737 13.9346 23.1844 13.4832 22.8507 13.1494C22.517 12.8157 22.0655 12.6264 21.5935 12.6223C21.1216 12.6182 20.6669 12.7996 20.3274 13.1275L19.8 13.6549V5.4001C19.8 4.92271 19.6104 4.46487 19.2728 4.12731C18.9352 3.78974 18.4774 3.6001 18 3.6001C17.5226 3.6001 17.0648 3.78974 16.7272 4.12731C16.3897 4.46487 16.2 4.92271 16.2 5.4001V13.6549L15.6726 13.1275Z"
            fill="currentColor"
        />
        <path
            d="M5.40002 8.9999C5.40002 8.04512 5.77931 7.12945 6.45444 6.45432C7.12957 5.77919 8.04525 5.3999 9.00002 5.3999H10.8C11.2774 5.3999 11.7353 5.58954 12.0728 5.92711C12.4104 6.26468 12.6 6.72251 12.6 7.1999C12.6 7.67729 12.4104 8.13513 12.0728 8.47269C11.7353 8.81026 11.2774 8.9999 10.8 8.9999H9.00002V21.5999H12.6L14.4 25.1999H21.6L23.4 21.5999H27V8.9999H25.2C24.7226 8.9999 24.2648 8.81026 23.9272 8.47269C23.5897 8.13513 23.4 7.67729 23.4 7.1999C23.4 6.72251 23.5897 6.26468 23.9272 5.92711C24.2648 5.58954 24.7226 5.3999 25.2 5.3999H27C27.9548 5.3999 28.8705 5.77919 29.5456 6.45432C30.2207 7.12945 30.6 8.04512 30.6 8.9999V26.9999C30.6 27.9547 30.2207 28.8704 29.5456 29.5455C28.8705 30.2206 27.9548 30.5999 27 30.5999H9.00002C8.04525 30.5999 7.12957 30.2206 6.45444 29.5455C5.77931 28.8704 5.40002 27.9547 5.40002 26.9999V8.9999Z"
            fill="currentColor"
        />
    </svg>
)

export default Deposit
