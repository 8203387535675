import React from 'react'
import tw from 'twin.macro'

const Personal = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.4 18.0001C32.4 21.8192 30.8828 25.4819 28.1823 28.1824C25.4818 30.883 21.8191 32.4001 18 32.4001C14.1809 32.4001 10.5182 30.883 7.81764 28.1824C5.11711 25.4819 3.59998 21.8192 3.59998 18.0001C3.59998 14.181 5.11711 10.5183 7.81764 7.81776C10.5182 5.11724 14.1809 3.6001 18 3.6001C21.8191 3.6001 25.4818 5.11724 28.1823 7.81776C30.8828 10.5183 32.4 14.181 32.4 18.0001ZM21.6 12.6001C21.6 13.5549 21.2207 14.4705 20.5456 15.1457C19.8704 15.8208 18.9548 16.2001 18 16.2001C17.0452 16.2001 16.1295 15.8208 15.4544 15.1457C14.7793 14.4705 14.4 13.5549 14.4 12.6001C14.4 11.6453 14.7793 10.7296 15.4544 10.0545C16.1295 9.37938 17.0452 9.0001 18 9.0001C18.9548 9.0001 19.8704 9.37938 20.5456 10.0545C21.2207 10.7296 21.6 11.6453 21.6 12.6001ZM18 19.8001C16.2767 19.7997 14.5895 20.2942 13.1389 21.2246C11.6884 22.1551 10.5355 23.4824 9.81717 25.0489C10.83 26.2272 12.0857 27.1725 13.4981 27.82C14.9105 28.4674 16.4462 28.8018 18 28.8001C19.5537 28.8018 21.0895 28.4674 22.5019 27.82C23.9143 27.1725 25.17 26.2272 26.1828 25.0489C25.4645 23.4824 24.3116 22.1551 22.861 21.2246C21.4105 20.2942 19.7233 19.7997 18 19.8001Z"
            fill="currentColor"
        />
    </svg>
)

export default Personal
